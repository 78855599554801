class CookieNotification {

    constructor(config = {}) {
        this.options = {
            container: '.cookie-notification',
            button_accept: '[data-role="accept"]',
            button_settings: '[data-role="settings"]',
            url: '/cookies-accept',
            ...config,
        };
    }

    init() {
        const container = document.querySelector(this.options.container);
        if (!container) {
            return false;
        }

        const btnAccept = container.querySelector(this.options.button_accept);
        if (!btnAccept) {
            return false;
        }

        btnAccept.addEventListener('click', async () => {
            const {status} = await this.accept();
            if (status === 200) {
                container.style.display = 'none';
            }
        });
    }

    async accept() {
        const response = await fetch('/cookies-accept', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-TOKEN': window.token.content,
            },
        });

        const status = await response.status;
        const data = await response.json();

        return {
            status: status,
            data: data,
        }
    }
}

window.addEventListener('load', () => {
    const cookies = new CookieNotification();
    cookies.init();
});
