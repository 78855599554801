require('./../bootstrap');
require('./../main-menu');
require('./../blazy');
require('./../fonts');
require('./../select-location');

window.addEventListener('load', () => {
    // const app = document.querySelector('#app');
    // if (app) {
    //     app.style.minHeight = window.innerHeight + 'px';
    // }
})
