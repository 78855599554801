// import loadcss from 'loadcss';

window.load_fonts = (fonts) => {
    if (!sessionStorage.fontsLoaded) {
        Promise.all(fonts).then(() => {
            document.querySelector('body').classList.add('fonts-loaded');
            sessionStorage.fontsLoaded = true;
        }).catch(error => {
            console.log(error);
        });
    } else {
        document.querySelector('body').classList.add('fonts-loaded');
    }
};

// const FontFaceObserver = require('fontfaceobserver');
window.addEventListener('load', () => {
    // loadcss('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
    //
    // const openSansRegular = new FontFaceObserver('Rubik', {
    //     weight: 'normal'
    // });

    window.load_fonts([
        //
    ])
});
