class MainMenu {
    constructor(config = {}) {
        this.options = {
            button_toggle: '.btn-toggle', body_class: 'offcanvas__open', ...config
        };
    }

    init() {
        const btnToggle = document.querySelector(this.options.button_toggle);
        const app = document.querySelector('#app');
        btnToggle.addEventListener('click', () => {
            const width = `${window.innerWidth}px`;
            if (document.body.classList.contains(this.options.body_class)) {
                document.body.classList.remove(this.options.body_class);
                app.removeAttribute('style');
            } else {
                document.body.classList.add(this.options.body_class);
                app.style.width = width;
            }
        });
    }
}

window.addEventListener('load', () => {
    (new MainMenu()).init();
});
