class SelectLocation {
    constructor(config = {}) {
        this.options = {
            el: '.select-location',
            placeholder: '---',
            ...config
        };
    }

    init() {
        const elements = document.querySelectorAll(this.options.el);

        elements.forEach(element => {
            this.buildTarget(element.value, element.dataset.target);

            element.addEventListener('change', e => {
                this.buildTarget(e.target.value, e.target.dataset.target);
            });
        });
    }

    buildTarget(currentLocationId, targetSelector) {
        const locationId = parseInt(currentLocationId);
        if (isNaN(locationId)) {
            return this.buildTargetOptions(targetSelector);
        }

        fetch(`/locations/${locationId}/areas`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-TOKEN': window.token.content,
            },
        }).then(response => {
            return response.json();
        }).then((response) => {
            this.buildTargetOptions(targetSelector, response.data);
        });
    }

    buildTargetOptions(targetSelector, items = []) {
        const target = document.querySelector(targetSelector);
        if (!target) {
            return false;
        }

        const placeholderTitle = target.dataset.placeholder || this.options.placeholder;
        const selectedValue = parseInt(target.dataset.selected) || null;

        let options = `<option value="">${placeholderTitle}</option>`;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.id === selectedValue) {
                options += `<option value="${item.id}" selected="selected">${item.name}</option>`;
            } else {
                options += `<option value="${item.id}">${item.name}</option>`;
            }
        }

        target.innerHTML = options;
    }
}

window.addEventListener('load', () => {
    (new SelectLocation()).init();
});
