// window.axios = require('axios');

window.token = document.head.querySelector('meta[name="csrf-token"]');

// if (window.token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.token.content;
// }
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('./cookie-notification');

window.addEventListener('load', () => {
    const btnFacebookShare = document.querySelector('.post-share__button.facebook');
    if (btnFacebookShare) {
        btnFacebookShare.addEventListener('click', (e) => {
            e.preventDefault();

            const url = btnFacebookShare.dataset.url;
            if (!url) {
                return false;
            }

            const width = btnFacebookShare.dataset.width || 800
            const height = btnFacebookShare.dataset.height || 600

            window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-share-dialog', `width=${width},height=${height}`);

            return false;
        })
    }

    const btnScrollTop = document.querySelector('.btn-scroll-top');
    if (btnScrollTop) {
        btnScrollTop.addEventListener('click', e => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })
    }
});
